var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "lock", "fill-height": "", tag: "section" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-slide-y-transition",
            { attrs: { appear: "" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "mt-6 mt-md-0",
                  attrs: { light: "", "max-width": "100%", width: "250" }
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "mt-n12 mb-6 elevation-12",
                          attrs: { color: "grey", size: "88" }
                        },
                        [
                          _c("v-img", {
                            attrs: { src: "faces/avatar-poli.png" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "display-2 font-weight-light mb-3 text--darken-2 grey--text"
                        },
                        [_vm._v("\n            Tania Andrew\n          ")]
                      ),
                      _c("v-text-field", {
                        staticClass: "mb-8",
                        attrs: { color: "secondary", label: "Enter Password" }
                      }),
                      _c("pages-btn", [
                        _vm._v("\n            Unlock\n          ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }